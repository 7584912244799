import React from "react";
import { Card, Col, Row } from "antd";
import ContactForm from "../components/contactForm";

const Landingpage = () => {
  return (
    <div
      style={{
        padding: "2em",
        backgroundImage: "url(/assets/images/Hero-3.jpg)",
      }}
    >
      <Row style={{ justifyContent: "center" }} gutter={16}>
        <Col span={4}>
          <img
            style={{ display: "flex", width: "80%" }}
            alt=""
            src="/assets/images/Techxovek logo main.png"
          ></img>
        </Col>
        <Col span={8}>
          <div>
            <h1 style={{ fontSize: "300%" }}>
              Digital Solutions for Growing SMEs & MSMEs
            </h1>
            <p style={{ fontSize: "300%" }}>Trusted by over 100 brands</p>
          </div>
        </Col>
        <Col span={8}>
          <Card>
            <div
              style={{
                height: "50%",
              }}
            >
              <h1
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bolder",
                }}
              >
                Let's Connect
              </h1>
              <div>
                <ContactForm />
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Landingpage;
