import { notification } from "antd";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import "../styles/form.css";

const ContactForm = () => {
  const { control, register, handleSubmit, reset } = useForm();

  const onSubmit = (data, e) => {
    e.target.reset();
    notification["success"]({
      message: "Your form submitted succesfully",
    });
  };

  return (
    <div
      style={{
        maxWidth: "600px",
        margin: "0 auto",
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <input
            style={{
              display: "block",
              boxSizing: "border-box",
              width: "100%",
              borderRadius: "4px",
              border: "1px solid grey",
              padding: "10px 15px",
              marginBottom: "10px",
              fontSize: "14px",
            }}
            placeholder="Company Name"
            {...register("firstName")}
          />
        </div>

        <div>
          <Controller
            name="Services required For"
            control={control}
            render={({ field }) => (
              <Select
                isClearable
                placeholder="Services required For"
                {...field}
                options={[
                  {
                    value: "Business Consulting",
                    label: "Business Consulting",
                  },
                  {
                    value: "Product engineering",
                    label: "Product Engineering",
                  },
                  {
                    value: "Web/App Development",
                    label: "Web/App Development",
                  },
                  { value: "Data Analytics", label: "Data Analytics" },
                  { value: "Cloud Services", label: "Cloud Sevices" },
                ]}
              />
            )}
          />
        </div>
        <div>
          <Controller
            control={control}
            name="phone"
            rules={{ required: true }}
            render={({ field: { ref, ...field } }) => (
              <PhoneInput
                {...field}
                inputExtraProps={{
                  ref,
                  required: true,
                  autoFocus: true,
                }}
                country={"in"}
                onlyCountries={["in"]}
                countryCodeEditable={true}
                specialLabel=""
              />
            )}
          />
        </div>

        <div>
          <input
            style={{
              display: "block",
              boxSizing: "border-box",
              width: "100%",
              borderRadius: "4px",
              border: "1px solid grey",
              padding: "10px 15px",
              marginBottom: "10px",
              fontSize: "14px",
            }}
            placeholder="Email"
            type="email"
            {...register("email")}
          />
        </div>
        <input
          style={{
            display: "block",
            boxSizing: "border-box",
            background: "#475e66",
            width: "100%",
            color: "white",
            borderRadius: "4px",
            border: "1px solid #475e66",
            padding: "10px 15px",
            marginTop: "20px",
            fontSize: "18px",
            fontWeight: "bolder",
            cursor: "pointer",
          }}
          onClick={() => reset()}
          type="submit"
        />
      </form>
    </div>
  );
};

export default ContactForm;
