import React from "react";
import { useRef } from "react";
import { Layout, Menu } from "antd";
import {
  GlobalOutlined,
  HomeFilled,
  InstagramOutlined,
  LinkedinOutlined,
  PhoneFilled,
  QuestionCircleFilled,
  TrademarkCircleOutlined,
  TwitterOutlined,
} from "@ant-design/icons";

import Landingpage from "./pages/landingpage";
import Services from "./pages/services";
import ContactUs from "./pages/contactUs";
import { Header } from "antd/es/layout/layout";
import WhyUs from "./pages/whyUs";

const { Footer } = Layout;

const Home = () => {
  const landingPage = useRef(null);
  const services = useRef(null);
  const contactUs = useRef(null);
  const whyUs = useRef(null);
  const componentMap = {
    landingPage,
    services,
    contactUs,
    whyUs,
  };
  const scrollToSection = (elementRef) => {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <Layout className="layout">
      <Header>
        <div
          style={{
            padding: "0px 0px",
            display: "flex",
            width: "fit-content",
          }}
        >
          <img alt="logo" src="/assets/images/nav-main.jpg" />

          <Menu
            theme="dark"
            onClick={({ key }) => {
              scrollToSection(componentMap[key]);
            }}
            mode="horizontal"
            items={[
              {
                label: "Home",
                key: "landingPage",
                icon: <HomeFilled />,
              },
              {
                label: "Services",
                key: "services",
                icon: <GlobalOutlined />,
              },
              {
                label: "Why us",
                key: "whyUs",
                icon: <QuestionCircleFilled />,
              },

              {
                label: "Contact us",
                key: "contactUs",
                icon: <PhoneFilled />,
              },
            ]}
          />
        </div>
      </Header>

      <div ref={landingPage}>
        <Landingpage />
      </div>
      <br />
      <div ref={services}>
        <Services />
      </div>
      <br />
      <div ref={whyUs}>
        <WhyUs />
      </div>
      <br />
      <div ref={contactUs}>
        <ContactUs />
      </div>
      <br />

      <Footer style={{ textAlign: "center", background: "#001529" }}>
        <div
          style={{
            padding: "1em",
            color: "white",
            fontWeight: "bolder",
          }}
        >
          Follow us on:
          <a
            style={{
              cursor: "pointer",
            }}
            href="https://www.linkedin.com/company/techxovek/?originalSubdomain=tr"
          >
            <span
              style={{
                padding: "1em",
              }}
            >
              <LinkedinOutlined style={{ fontSize: "200%" }} />
            </span>
          </a>
          <a
            style={{
              cursor: "pointer",
            }}
            href="https://www.instagram.com/techxovek/?hl=en"
          >
            <span
              style={{
                padding: "1em",
              }}
            >
              <InstagramOutlined style={{ fontSize: "200%" }} />
            </span>
          </a>
          <a
            style={{
              cursor: "pointer",
            }}
            href="https://mobile.twitter.com/techxovek"
          >
            <span
              style={{
                padding: "1em",
              }}
            >
              <TwitterOutlined style={{ fontSize: "200%" }} />
            </span>
          </a>
        </div>
        <div
          style={{
            color: "white",
            fontWeight: "bolder",
          }}
        >
          <TrademarkCircleOutlined />
          2022 Visionex Techsus India private Limited, All rights reserved
        </div>
      </Footer>
    </Layout>
  );
};

export default Home;
