import React from "react";
import { Col, Row } from "antd";

const ContactUs = () => {
  return (
    <div
      style={{
        padding: "2em",
        backgroundImage: "url(/assets/images/Hero-3.jpg)",
      }}
    >
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          fontSize: "200%",
        }}
      >
        CONTACT DETAILS
      </h1>
      <Row style={{ justifyContent: "space-between" }} gutter={16}>
        <Col span={12}>
          <div>
            <h1>Let’s Get Started</h1>
            <p style={{ fontWeight: "bolder" }}>
              We offer full-cycle technology solutions that streamline your
              digital transformation journey. With cutting-edge technical
              expertise, TechXovek simplifies complex business challenges with
              innovative solutions for 100+ fastest-growing enterprises.
            </p>
          </div>
        </Col>
        <Col span={6}>
          <div style={{ fontWeight: "bolder" }}>
            <h1>Contacts</h1>
            <p>Email: admin@techxovek.com</p>
            <p>Call: +919535900593</p>
            <p>
              Address: 22/1, Satkari Mitra Ln, Bagmari, Kolkata, West Bengal
              700054
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ContactUs;
