import React from "react";
import { Card, Col, Row } from "antd";

const { Meta } = Card;

const Services = () => {
  return (
    <div
      style={{
        padding: "2em",
        backgroundImage: "url(/assets/images/Hero-3.jpg)",
      }}
    >
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          fontSize: "200%",
        }}
      >
        OUR SERVICES
      </h1>
      <Row gutter={16}>
        <Col span={6}>
          <Card
            hoverable
            style={{ width: "100vw/6", height: "100vh/2" }}
            cover={<img alt="example" src="/assets/images/img-1.jpg" />}
          >
            <Meta title="Cloud Services" />
          </Card>
        </Col>
        <Col span={6}>
          <Card
            hoverable
            style={{ width: "100vw/6", height: "100vh/2" }}
            cover={<img alt="example" src="/assets/images/img-2.jpg" />}
          >
            <Meta title="Business and Application Services" />
          </Card>
        </Col>
        <Col span={6}>
          <Card
            hoverable
            style={{ width: "100vw/6", height: "100vh/2" }}
            cover={<img alt="example" src="/assets/images/img-3.jpg" />}
          >
            <Meta title="Managed Infrastructure Services" />
          </Card>
        </Col>

        <Col span={6}>
          <Card
            hoverable
            style={{ width: "100vw/6", height: "100vh/2" }}
            cover={<img alt="example" src="/assets/images/img-4.jpg" />}
          >
            <Meta title="Digital Marketing" />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Services;
