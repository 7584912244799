import React from "react";
import { Card, Col, Row } from "antd";

const WhyUs = () => {
  return (
    <div
      style={{
        padding: "2em",
        backgroundImage: "url(/assets/images/Hero-3.jpg)",
      }}
    >
      <h1
        style={{
          display: "flex",
          justifyContent: "center",
          fontSize: "200%",
        }}
      >
        WHY US
      </h1>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "1rem 4rem",
          textAlign: "center",
        }}
      >
        <h2>
          At TechXovek we believe in your ideas and leverage our extensive
          knowledge to bring them to life. Our consultative approach is
          analytics-driven. We harness the power of technology to ring
          innovative customer-centric solutions for your success.
        </h2>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "1rem",
          textAlign: "center",
        }}
      >
        <h2>
          We are redefining the path of your growth with high-quality
          craftsmanship by leveraging disruptive technologies.
        </h2>
      </div>

      <Row gutter={16}>
        <Col span={6}>
          <Card
            style={{
              backgroundImage: "url(/assets/images/rock-2.jpg)",
              height: "225px",
            }}
          >
            <h1 style={{ color: "white" }}>Digital Services</h1>
            <p style={{ color: "white" }}>
              One house for the complete digital solution which lowers cost and
              enables total ownership of the project with superior quality and
              timely delivery.
            </p>
          </Card>
        </Col>
        <Col span={6}>
          <Card
            style={{
              backgroundImage: "url(/assets/images/rock-2.jpg)",
              height: "225px",
            }}
          >
            <h1 style={{ color: "white" }}>Enterprise-Grade</h1>
            <p style={{ color: "white" }}>
              Successful track record of delivering mission-critical, scalable,
              digital innovation for global enterprises with advanced solutions
              and top-notch technologies
            </p>
          </Card>
        </Col>
        <Col span={6}>
          <Card
            style={{
              backgroundImage: "url(/assets/images/rock-2.jpg)",
              height: "225px",
            }}
          >
            <h1 style={{ color: "white" }}>Flexibility</h1>
            <p style={{ color: "white" }}>
              We are a customer-centric business. We develop partnerships to
              create solid value for our clients through flexible engagement and
              pricing models.
            </p>
          </Card>
        </Col>

        <Col span={6}>
          <Card
            style={{
              backgroundImage: "url(/assets/images/rock-2.jpg)",
              height: "225px",
            }}
          >
            <h1 style={{ color: "white" }}>Agile Design</h1>
            <p style={{ color: "white" }}>
              We are a small company with huge capabilities. We have retained
              our agility through our unique and friendly company culture and
              organizational structure.
            </p>
          </Card>
        </Col>
      </Row>
      <div
        style={{
          padding: "3rem",
          display: "flex",
          justifyContent: "center",
        }}
      ></div>
    </div>
  );
};

export default WhyUs;
